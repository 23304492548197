import { FC } from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@gbm/queen-ui-guidelines';

import PlaceholdersTable from 'components/PlaceholdersTable';
import { getStatusToDisplay } from 'utils/transactionsUtils';
import { formatDateTimeTZUser } from 'utils/dates';
import styles from './styles.module.scss';
interface ValidationToShowCard {
  loading: boolean;
  user?: string;
  username?: string;
  date?: string;
  status: string;
  comments: string;
  complementaryComments?: string;
}

const ValidationToShowCard: FC<ValidationToShowCard> = ({
  status,
  comments,
  complementaryComments,
  loading,
  date,
  user,
  username,
}) => {
  const renderContent = () => {
    if (loading) {
      return (
        <PlaceholdersTable
          placeholderCols={2}
          placeholderRows={4}
          width="100%"
        />
      );
    }

    return (
      <>
        <TableRow>
          <TableCell>
            <Typography component="strong" variant="subHeader">
              Status
            </Typography>
          </TableCell>
          <TableCell>
            <Typography>{getStatusToDisplay(status)}</Typography>
          </TableCell>
        </TableRow>
        {user && (
          <TableRow>
            <TableCell>
              <Typography component="strong" variant="subHeader">
                User
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>{user}</Typography>
            </TableCell>
          </TableRow>
        )}
        {username && (
          <TableRow>
            <TableCell>
              <Typography component="strong" variant="subHeader">
                Username
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>{username}</Typography>
            </TableCell>
          </TableRow>
        )}
        {date && (
          <TableRow>
            <TableCell>
              <Typography component="strong" variant="subHeader">
                Date created
              </Typography>
            </TableCell>
            <TableCell>
              <Typography>{formatDateTimeTZUser(date)}</Typography>
            </TableCell>
          </TableRow>
        )}
        <TableRow>
          <TableCell>
            <Typography component="strong" variant="subHeader">
              Comment
            </Typography>
          </TableCell>
          <TableCell className={styles.cellComment}>
            <Typography>{comments}</Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography component="strong" variant="subHeader">
              Supplementary comment
            </Typography>
          </TableCell>
          <TableCell className={styles.cellComment}>
            <Typography>{complementaryComments}</Typography>
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <Table className={styles.tableValidations}>
      <TableBody>{renderContent()}</TableBody>
    </Table>
  );
};

export default ValidationToShowCard;
