import { createRoutine } from 'redux-saga-routines';
import extendRoutine from 'utils/routines';

import {
  GET_TRANSACTIONS,
  GET_TRANSACTION,
  MANUAL_REVIEW_TRANSACTION,
  ADD_MANUAL_REVIEW_TRANSACTION,
} from './constants';

export const getTransactions = extendRoutine(
  createRoutine(GET_TRANSACTIONS),
  'RESET',
);

export const getTransaction = extendRoutine(
  createRoutine(GET_TRANSACTION),
  'RESET',
);

export const manualReviewTransaction = extendRoutine(
  createRoutine(MANUAL_REVIEW_TRANSACTION),
  'RESET',
);

export const addManualReviewTransaction = extendRoutine(
  createRoutine(ADD_MANUAL_REVIEW_TRANSACTION),
  'RESET',
);
