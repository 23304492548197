import { useContext } from 'react';

import { TransactionsContext } from './index';

export function useSelectorsTransactions() {
  const { state } = useContext(TransactionsContext);

  return {
    transactionsState: state?.transactions,
    transactionState: state?.transaction,
    createManualReviewState: state?.createManualReview,
    transactionsToSave: state?.transactionsToSave,
  };
}
