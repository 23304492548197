import { FC } from 'react';
import {
  Margin,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from '@gbm/queen-ui-guidelines';
import { Response } from 'types/Transaction';
import { getStatusToDisplay } from 'utils/transactionsUtils';
import { nanoid } from 'nanoid';

export interface ModalTransactionResultMoreInfoProps {
  onClose: () => void;
  isOpen: boolean;
  transactionalAnalysisResponse: Response | undefined;
}

const ModalTransactionResultMoreInfo: FC<
  ModalTransactionResultMoreInfoProps
> = ({ onClose, isOpen, transactionalAnalysisResponse }) => {
  return (
    <Modal
      id="ModalTransactionResultMoreInfo"
      data-testid="modalTransactionResultMoreInfo"
      isOpen={isOpen}
      size="lg"
      scrollable
      centered
    >
      <ModalHeader onClose={onClose}>Detail Result</ModalHeader>
      <ModalBody>
        <Margin side="bottom" xs={24}>
          <Table>
            <TableBody>
              <TableRow key={nanoid()}>
                <TableCell>
                  <Typography component="strong" variant="subHeader">
                    Match:
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    {getStatusToDisplay(transactionalAnalysisResponse?.match)}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow key={nanoid()}>
                <TableCell>
                  <Typography component="strong" variant="subHeader">
                    Match action:
                  </Typography>
                </TableCell>
                <TableCell>
                  more
                  <Typography>
                    {getStatusToDisplay(
                      transactionalAnalysisResponse?.matchAction,
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow key={nanoid()}>
                <TableCell>
                  <Typography component="strong" variant="subHeader">
                    Match level:
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    {transactionalAnalysisResponse?.matchLevel}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Margin>
      </ModalBody>
      <ModalFooter>
        <Button
          id="btnCloseModalComplementary"
          data-testid="btnCloseModalComplementary"
          type="button"
          color="light"
          onClick={onClose}
        >
          Cerrar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalTransactionResultMoreInfo;
