import { FC } from 'react';
import { Analysis, DetailMatchTransactionalAnalysis } from 'types/Transaction';
import { Typography, Grid, Margin } from '@gbm/queen-ui-guidelines';
import { BlockIcon, CheckmarkCircleIcon, WarningIcon } from 'components/Icons';
import { nanoid } from 'nanoid';
import styles from './styles.module.scss';

type SummaryAlertsProps = {
  matchDetail: DetailMatchTransactionalAnalysis;
};
const SummaryAlerts: FC<SummaryAlertsProps> = ({ matchDetail }) => {
  const AnalyseWarningInfo = (analysis: Analysis[]) => {
    return (
      <div className={styles.containerIcons}>
        {
          // eslint-disable-next-line react/destructuring-assignment
          analysis.map(({ warning, analysisStatus }) =>
            analysisStatus.toLowerCase() !== 'ok' ? (
              <BlockIcon key={nanoid()} />
            ) : !warning === true ? (
              <CheckmarkCircleIcon key={nanoid()} />
            ) : (
              <WarningIcon key={nanoid()} />
            ),
          )
        }
      </div>
    );
  };
  return (
    <>
      <Margin side="bottom" xs={24}>
        <Typography component="strong" variant="header">
          Summary alerts
        </Typography>
      </Margin>
      <Grid row justify="center">
        <Grid md={4}>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <Typography variant="subHeader"> W0 Alerts</Typography>
            {AnalyseWarningInfo(matchDetail.analysisW0)}
          </div>
        </Grid>
        <Grid md={4}>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <Typography variant="subHeader"> W1 Alerts</Typography>
            {AnalyseWarningInfo(matchDetail.analysisW1)}
          </div>
        </Grid>
        <Grid md={4}>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <Typography variant="subHeader"> W2 Alerts</Typography>
            {AnalyseWarningInfo(matchDetail.analysisW2)}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default SummaryAlerts;
