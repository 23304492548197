export const TransactionStatus = {
  inProgress: 'IN_PROGRESS',
  inProgess: 'IN_PROGESS',
  rejected: 'REJECTED',
  accepted: 'ACCEPTED',
  manualReview: 'MANUAL_REVIEW',
  warning: 'WARNING',
  updated: 'UPDATED',
  reviewed: 'REVIEWED',
  matched: 'MATCHED',
};
