import { FC } from 'react';
import { TransactionalAnalysisData } from 'types/Transaction';
import {
  Margin,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
} from '@gbm/queen-ui-guidelines';
import { nanoid } from 'nanoid';
import { formatDateUTC } from 'utils/dates';
import { formatCurrency } from 'utils/formatCurrency';
import { isEmpty } from 'lodash';
import ExtraInfo from 'pages/TransactionDetailV2/ExtraInfo';
import styles from './styles.module.scss';

type TransactionalInformationTxAnalysisProps = {
  selectedTransactionalAnalysisData: TransactionalAnalysisData | null;
  handleOpenModalDetailData: (extraData: any) => void;
};

const TransactionalInformationTxAnalysis: FC<
  TransactionalInformationTxAnalysisProps
> = ({ selectedTransactionalAnalysisData, handleOpenModalDetailData }) => {
  const renderContent = () => {
    return (
      <>
        <TableRow key={nanoid()}>
          <TableCell className={styles.tableCellWrapper}>
            <Typography component="strong" variant="subHeader">
              Operation number:
            </Typography>
          </TableCell>
          <TableCell className={styles.tableCellWrapper}>
            <Typography>
              {selectedTransactionalAnalysisData?.operation.number ?? 0}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow key={nanoid()}>
          <TableCell className={styles.tableCellWrapper}>
            <Typography component="strong" variant="subHeader">
              Amount:
            </Typography>
          </TableCell>
          <TableCell className={styles.tableCellWrapper}>
            <Typography>
              {formatCurrency(
                selectedTransactionalAnalysisData?.operation.amount ?? 0,
              )}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow key={nanoid()}>
          <TableCell className={styles.tableCellWrapper}>
            <Typography component="strong" variant="subHeader">
              Type:
            </Typography>
          </TableCell>
          <TableCell className={styles.tableCellWrapper}>
            <Typography>
              {selectedTransactionalAnalysisData?.operation.type}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow key={nanoid()}>
          <TableCell className={styles.tableCellWrapper}>
            <Typography component="strong" variant="subHeader">
              Currency:
            </Typography>
          </TableCell>
          <TableCell className={styles.tableCellWrapper}>
            <Typography>
              {selectedTransactionalAnalysisData?.operation.currency}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow key={nanoid()}>
          <TableCell className={styles.tableCellWrapper}>
            <Typography component="strong" variant="subHeader">
              Reference:
            </Typography>
          </TableCell>
          <TableCell className={styles.tableCellWrapper}>
            <Typography>
              {selectedTransactionalAnalysisData?.operation.reference}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow key={nanoid()}>
          <TableCell className={styles.tableCellWrapper}>
            <Typography component="strong" variant="subHeader">
              Creation date:
            </Typography>
          </TableCell>
          <TableCell className={styles.tableCellWrapper}>
            <Typography>
              {formatDateUTC(
                selectedTransactionalAnalysisData?.operation.creationDate ?? '',
              )}
            </Typography>
          </TableCell>
        </TableRow>
      </>
    );
  };

  const renderAccordionDetailTransaction = () => {
    if (!isEmpty(selectedTransactionalAnalysisData?.operation?.details)) {
      return (
        <Accordion collapsible multiple defaultIndex={0}>
          <AccordionItem key={nanoid()}>
            <AccordionHeader
              controlLabel="Extra Details"
              className="d-flex justify-content-between"
            ></AccordionHeader>
            <AccordionPanel>
              <ExtraInfo
                extraInfo={
                  selectedTransactionalAnalysisData?.operation?.details
                }
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      );
    }
  };

  const renderAccordionExchange = () => {
    return (
      <Accordion collapsible multiple defaultIndex={0}>
        <AccordionItem key={nanoid()} expanded={true}>
          <AccordionHeader
            controlLabel="Exchange"
            className="d-flex justify-content-between"
          >
            <Button
              color="link"
              onClick={() =>
                handleOpenModalDetailData(
                  selectedTransactionalAnalysisData?.operation?.exchange
                    ?.details,
                )
              }
              disabled={isEmpty(
                selectedTransactionalAnalysisData?.operation?.exchange?.details,
              )}
            >
              <span>More info</span>
            </Button>
          </AccordionHeader>
          <AccordionPanel>
            <Table>
              <TableBody>
                <TableRow key={nanoid()}>
                  <TableCell className={styles.tableCellWrapper}>
                    <Typography component="strong" variant="subHeader">
                      From amount:
                    </Typography>
                  </TableCell>
                  <TableCell className={styles.tableCellWrapper}>
                    <Typography>
                      {formatCurrency(
                        selectedTransactionalAnalysisData?.operation.exchange
                          .fromAmount ?? 0,
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow key={nanoid()}>
                  <TableCell className={styles.tableCellWrapper}>
                    <Typography component="strong" variant="subHeader">
                      From currency:
                    </Typography>
                  </TableCell>
                  <TableCell className={styles.tableCellWrapper}>
                    <Typography>
                      {selectedTransactionalAnalysisData?.operation.exchange
                        .fromCurrency ?? 'N/A'}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow key={nanoid()}>
                  <TableCell className={styles.tableCellWrapper}>
                    <Typography component="strong" variant="subHeader">
                      Rate:
                    </Typography>
                  </TableCell>
                  <TableCell className={styles.tableCellWrapper}>
                    <Typography>
                      {selectedTransactionalAnalysisData?.operation.exchange
                        .rate ?? 'N/A'}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow key={nanoid()}>
                  <TableCell className={styles.tableCellWrapper}>
                    <Typography component="strong" variant="subHeader">
                      To amount:
                    </Typography>
                  </TableCell>
                  <TableCell className={styles.tableCellWrapper}>
                    <Typography>
                      {formatCurrency(
                        selectedTransactionalAnalysisData?.operation.exchange
                          .toAmount,
                      ) ?? 'N/A'}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow key={nanoid()}>
                  <TableCell className={styles.tableCellWrapper}>
                    <Typography component="strong" variant="subHeader">
                      To currency:
                    </Typography>
                  </TableCell>
                  <TableCell className={styles.tableCellWrapper}>
                    <Typography>
                      {selectedTransactionalAnalysisData?.operation.exchange
                        .toCurrency ?? 'N/A'}
                    </Typography>
                  </TableCell>
                </TableRow>
                {renderContent()}
              </TableBody>
            </Table>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    );
  };

  const renderAccordionOrdenant = () => {
    return (
      <Accordion collapsible multiple defaultIndex={0}>
        <AccordionItem key={nanoid()}>
          <AccordionHeader
            controlLabel="Ordenant"
            className="d-flex justify-content-between"
          >
            <Button
              color="link"
              onClick={() =>
                handleOpenModalDetailData(
                  selectedTransactionalAnalysisData?.operation?.ordenant,
                )
              }
              disabled={isEmpty(
                selectedTransactionalAnalysisData?.operation?.ordenant?.details,
              )}
            >
              <span>More info</span>
            </Button>
          </AccordionHeader>
          <AccordionPanel>
            <Table>
              <TableBody>
                <TableRow key={nanoid()}>
                  <TableCell className={styles.tableCellWrapper}>
                    <Typography component="strong" variant="subHeader">
                      Name:
                    </Typography>
                  </TableCell>
                  <TableCell className={styles.tableCellWrapper}>
                    <Typography>
                      {selectedTransactionalAnalysisData?.operation.ordenant
                        .name ?? 'N/A'}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow key={nanoid()}>
                  <TableCell className={styles.tableCellWrapper}>
                    <Typography component="strong" variant="subHeader">
                      Account:
                    </Typography>
                  </TableCell>
                  <TableCell className={styles.tableCellWrapper}>
                    <Typography>
                      {selectedTransactionalAnalysisData?.operation.ordenant
                        .account ?? 'N/A'}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow key={nanoid()}>
                  <TableCell className={styles.tableCellWrapper}>
                    <Typography component="strong" variant="subHeader">
                      Institution country:
                    </Typography>
                  </TableCell>
                  <TableCell className={styles.tableCellWrapper}>
                    <Typography>
                      {selectedTransactionalAnalysisData?.operation.ordenant
                        .institutionCountry ?? 'N/A'}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow key={nanoid()}>
                  <TableCell className={styles.tableCellWrapper}>
                    <Typography component="strong" variant="subHeader">
                      Institution name:
                    </Typography>
                  </TableCell>
                  <TableCell className={styles.tableCellWrapper}>
                    <Typography>
                      {selectedTransactionalAnalysisData?.operation.ordenant
                        .institutionName ?? 'N/A'}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow key={nanoid()}>
                  <TableCell className={styles.tableCellWrapper}>
                    <Typography component="strong" variant="subHeader">
                      Institution number:
                    </Typography>
                  </TableCell>
                  <TableCell className={styles.tableCellWrapper}>
                    <Typography>
                      {selectedTransactionalAnalysisData?.operation.ordenant
                        .institutionNumber ?? 'N/A'}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    );
  };

  const renderAccordionBeneficiarie = () => {
    return (
      <Accordion collapsible multiple defaultIndex={0}>
        <AccordionItem key={nanoid()}>
          <AccordionHeader
            controlLabel="Beneficiary"
            className="d-flex justify-content-between"
          >
            <Button
              color="link"
              onClick={() =>
                handleOpenModalDetailData(
                  selectedTransactionalAnalysisData?.operation?.beneficiary
                    ?.details,
                )
              }
              disabled={isEmpty(
                selectedTransactionalAnalysisData?.operation?.beneficiary
                  ?.details,
              )}
            >
              <span>More info</span>
            </Button>
          </AccordionHeader>
          <AccordionPanel>
            <Table>
              <TableBody>
                <TableRow key={nanoid()}>
                  <TableCell className={styles.tableCellWrapper}>
                    <Typography component="strong" variant="subHeader">
                      Name:
                    </Typography>
                  </TableCell>
                  <TableCell className={styles.tableCellWrapper}>
                    <Typography>
                      {selectedTransactionalAnalysisData?.operation.beneficiary
                        .name ?? 'N/A'}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow key={nanoid()}>
                  <TableCell className={styles.tableCellWrapper}>
                    <Typography component="strong" variant="subHeader">
                      Account:
                    </Typography>
                  </TableCell>
                  <TableCell className={styles.tableCellWrapper}>
                    <Typography>
                      {selectedTransactionalAnalysisData?.operation.beneficiary
                        .account ?? 'N/A'}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow key={nanoid()}>
                  <TableCell className={styles.tableCellWrapper}>
                    <Typography component="strong" variant="subHeader">
                      Institution country:
                    </Typography>
                  </TableCell>
                  <TableCell className={styles.tableCellWrapper}>
                    <Typography>
                      {selectedTransactionalAnalysisData?.operation.beneficiary
                        .institutionCountry ?? 'N/A'}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow key={nanoid()}>
                  <TableCell className={styles.tableCellWrapper}>
                    <Typography component="strong" variant="subHeader">
                      Institution name:
                    </Typography>
                  </TableCell>
                  <TableCell className={styles.tableCellWrapper}>
                    <Typography>
                      {selectedTransactionalAnalysisData?.operation.beneficiary
                        .institutionName ?? 'N/A'}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow key={nanoid()}>
                  <TableCell className={styles.tableCellWrapper}>
                    <Typography component="strong" variant="subHeader">
                      Institution number:
                    </Typography>
                  </TableCell>
                  <TableCell className={styles.tableCellWrapper}>
                    <Typography>
                      {selectedTransactionalAnalysisData?.operation.beneficiary
                        .institutionNumber ?? 'N/A'}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    );
  };

  return (
    <>
      <Margin side="bottom" xs={24}>
        <div className="d-flex">
          <Typography component="h4" align="left">
            Transaction information
          </Typography>
          <Button
            color="link"
            disabled={isEmpty(
              selectedTransactionalAnalysisData?.operation.details,
            )}
            onClick={() =>
              handleOpenModalDetailData(
                selectedTransactionalAnalysisData?.operation.details,
              )
            }
          >
            <span>More info</span>
          </Button>
        </div>
      </Margin>

      <Margin side="bottom" xs={24}>
        <>
          {renderAccordionOrdenant()}
          {renderAccordionBeneficiarie()}
          {renderAccordionExchange()}
          {renderAccordionDetailTransaction()}
        </>
      </Margin>
    </>
  );
};

export default TransactionalInformationTxAnalysis;
