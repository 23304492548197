import { envConfig } from 'config';
import request from '.';
import { RequestConfig } from 'types/RequestConfig';
import { ValidatorManualReview } from 'types/Transaction';

const API_BASE_URL = `${envConfig.API_BASE}`;

export function getTransactionsRequest(next?: string, options = {}) {
  return request(`${API_BASE_URL}/v2/transactions${next}`, {
    method: 'GET',
    ...options,
  } as RequestConfig);
}

export function getTransactionRequest(transactionId: string, options = {}) {
  return request(`${API_BASE_URL}/v2/transactions/${transactionId}`, {
    method: 'GET',
    ...options,
  } as RequestConfig);
}

export type ManualReviewBody = {
  validators: ValidatorManualReview[];
};

export function manualReviewTransactionRequest(
  transactionId: string,
  body: ManualReviewBody,
  options = {},
) {
  const validatorsMapped = body.validators.map((validator) => {
    return {
      request_id: validator.requestId,
      code: validator.code,
      status: validator.status,
      details: {
        comments: validator.details.comments,
        complementary_comments: validator.details.complementaryComments,
      },
    };
  });

  return request(
    `${API_BASE_URL}/v2/transactions/${transactionId}/manual-review`,
    {
      method: 'POST',
      body: { validators: validatorsMapped },
      ...options,
    } as RequestConfig,
  );
}

export function getBlacklistInfo(transactionId?: number, options = {}) {
  return request(`${API_BASE_URL}/v2/blacklists/entities/${transactionId}`, {
    method: 'GET',
    ...options,
  } as RequestConfig);
}
