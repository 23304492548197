import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Margin,
  Textarea,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@gbm/queen-ui-guidelines';

import DropdownController from 'components/common/DropdownController';
import { options } from 'constants/modalVerification';
import { ValidatorManualReview } from 'types/Transaction';

type FormValues = {
  /* TODO: Refactor this to not send all the properties and only send the value property */
  dropdownStatus: {
    value: string;
    displayName: string;
    code: string;
    id: string;
    label: string;
    name: string;
  };
  comment: string;
  supplementaryComment: string;
};

export interface ModalVerificationProps {
  dataVerification?: ValidatorManualReview | null;
  onClose: Function;
  onSubmit: Function;
  isOpen: boolean;
}

const ModalVerification: FC<ModalVerificationProps> = ({
  dataVerification,
  onClose,
  onSubmit,
  isOpen,
}) => {
  const {
    formState: { isDirty, isValid },
    handleSubmit,
    control,
    watch,
    register,
    reset,
  } = useForm<FormValues>({
    mode: 'onChange',
  });

  const { dropdownStatus } = watch();

  useEffect(() => {
    if (dataVerification) {
      reset({
        comment: dataVerification?.details.comments,
        supplementaryComment: dataVerification?.details.complementaryComments,
        dropdownStatus: options.find(
          (option) => option.value === dataVerification?.status,
        ),
      });
    }
  }, [dataVerification]);

  return (
    <Modal
      id="modalDetailCoincidences"
      data-testid="modalDetailCoincidences"
      isOpen={isOpen}
      size="lg"
      scrollable
      centered
    >
      <form
        onSubmit={handleSubmit((values) => {
          onSubmit(values);
          reset();
        })}
      >
        <ModalHeader onClose={onClose}>
          {dataVerification ? 'Update validation' : 'Add validation'}
        </ModalHeader>
        <ModalBody>
          <Margin side="bottom" xs={12}>
            <Controller
              render={({ field: { onChange } }) => (
                <DropdownController
                  id="dpStatus"
                  name="dropdownStatus"
                  label="Status"
                  placeholder={dropdownStatus?.displayName || 'Select status'}
                  options={options}
                  onChange={onChange}
                />
              )}
              control={control}
              name="dropdownStatus"
              rules={{ required: true }}
            />
          </Margin>
          <Margin side="bottom" xs={12}>
            <Textarea
              id="manualReviewComment"
              data-testid="manualReviewComment"
              label="Comment"
              rows={3}
              placeholder="Add a comment"
              {...register('comment', {
                required: 'Comment is required',
              })}
            />
          </Margin>
          <Margin side="bottom" xs={24}>
            <Textarea
              id="manualReviewSupplementaryComment"
              data-testid="manualReviewSupplementaryComment"
              label="Supplementary comment"
              rows={3}
              placeholder="Add a supplementary comment"
              {...register('supplementaryComment')}
            />
          </Margin>
        </ModalBody>
        <ModalFooter>
          <Button
            id="btnCloseModalVerification"
            data-testid="btnCloseModalVerification"
            type="button"
            color="light"
            onClick={() => {
              onClose();
              reset();
            }}
          >
            Cerrar
          </Button>
          <Button
            id="btnSubmitAddVerification"
            data-testid="btnSubmitAddVerification"
            type="submit"
            disabled={!(isValid && isDirty)}
          >
            {dataVerification ? 'Update' : 'Add'}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default ModalVerification;
