import produce from 'immer';
import { ValidatorManualReview } from 'types/Transaction';
import { TransactionsState, TransactionsToSave } from 'types/TransactionsState';

import {
  getTransactions,
  getTransaction,
  manualReviewTransaction,
} from './actions';
import {
  ADD_MANUAL_REVIEW_TRANSACTION,
  UPDATE_MANUAL_REVIEW_TRANSACTION,
} from './constants';

export const initialState: TransactionsState = {
  transactions: {
    loading: false,
    loaded: false,
    error: null,
    list: [],
  },
  transaction: {
    loading: false,
    loaded: false,
    error: null,
    item: null,
  },
  transactionsToSave: {
    countries: [],
    listmatching: [],
    txn: [],
  },
  createManualReview: {
    loading: false,
    loaded: false,
    error: null,
  },
};

export interface TransactionsAction {
  type: string;
  payload: any;
}

const reducer = (state: TransactionsState, action?: TransactionsAction) =>
  produce(state, (draftProduce) => {
    if (!action) return draftProduce;

    const { type, payload } = action;

    const draft = draftProduce;
    switch (type) {
      case getTransactions.REQUEST:
        draft.transactions.loading = true;
        draft.transactions.loaded = false;
        draft.transactions.error = null;
        draft.transactions.list = [];
        break;

      case getTransactions.SUCCESS:
        draft.transactions.loading = false;
        draft.transactions.loaded = true;
        draft.transactions.list = payload;
        break;

      case getTransactions.FAILURE:
        draft.transactions.loading = false;
        draft.transactions.loaded = false;
        draft.transactions.error = payload;
        break;

      case getTransactions.RESET:
        draft.transactions = { ...initialState.transactions };
        break;

      case getTransaction.REQUEST:
        draft.transaction.loading = true;
        draft.transaction.loaded = false;
        draft.transaction.error = null;
        draft.transaction.item = null;
        break;

      case getTransaction.SUCCESS:
        draft.transaction.loading = false;
        draft.transaction.loaded = true;
        draft.transaction.item = payload;
        break;

      case getTransaction.FAILURE:
        draft.transaction.loading = false;
        draft.transaction.loaded = false;
        draft.transaction.error = payload;
        break;

      case getTransaction.RESET:
        draft.transaction = { ...initialState.transaction };
        break;

      case manualReviewTransaction.REQUEST:
        draft.createManualReview.loading = true;
        draft.createManualReview.loaded = false;
        draft.createManualReview.error = null;
        break;

      case manualReviewTransaction.SUCCESS:
        draft.createManualReview.loading = false;
        draft.createManualReview.loaded = true;
        break;

      case manualReviewTransaction.FAILURE:
        draft.createManualReview.loading = false;
        draft.createManualReview.loaded = true;
        draft.createManualReview.error = payload;
        break;

      case manualReviewTransaction.RESET:
        draft.transactionsToSave = {
          countries: [],
          listmatching: [],
          txn: [],
        };
        draft.createManualReview = { ...initialState.createManualReview };
        break;

      case ADD_MANUAL_REVIEW_TRANSACTION: {
        const { validator, data } = payload;
        const transactionsToSavesCopy: TransactionsToSave = {
          ...draft.transactionsToSave,
        };
        const validatorCopy: ValidatorManualReview[] = transactionsToSavesCopy[
          validator as keyof typeof transactionsToSavesCopy
        ] as ValidatorManualReview[];
        const newTransaction: ValidatorManualReview[] = [
          ...validatorCopy,
          data,
        ] as ValidatorManualReview[];

        transactionsToSavesCopy[
          validator as keyof typeof transactionsToSavesCopy
        ] = newTransaction;

        draft.transactionsToSave = { ...transactionsToSavesCopy };
        break;
      }

      case UPDATE_MANUAL_REVIEW_TRANSACTION: {
        const { validator, data } = payload;
        const transactionsToSavesCopy: TransactionsToSave = {
          ...draft.transactionsToSave,
        };
        const validatorCopy: ValidatorManualReview[] = transactionsToSavesCopy[
          validator as keyof typeof transactionsToSavesCopy
        ] as ValidatorManualReview[];
        const indexValidator = validatorCopy.findIndex(
          (value) => value.requestId === data.requestId,
        );
        validatorCopy[indexValidator] = { ...data };
        draft.transactionsToSave = { ...transactionsToSavesCopy };
        break;
      }

      default:
        return draft;
    }
    return draft;
  });

export default reducer;
