import { TransactionStatus } from 'constants/transactionStatus';

export const getStatusToDisplay = (status: string | undefined) => {
  switch (status) {
    case TransactionStatus.inProgress:
      return 'In progress';
    case TransactionStatus.inProgess:
      return 'In progress';
    case TransactionStatus.rejected:
      return 'Rejected';
    case TransactionStatus.accepted:
      return 'Accepted';
    case TransactionStatus.manualReview:
      return 'Manual Review';
    case TransactionStatus.warning:
      return 'Warning';
    case TransactionStatus.updated:
      return 'Updated';
    case TransactionStatus.reviewed:
      return 'Reviewed';
    case TransactionStatus.matched:
      return 'Matched';

    default:
      return status;
  }
};

export const getColorForBadge = (status: string | undefined) => {
  switch (status) {
    case TransactionStatus.inProgess:
      return 'warning';
    case TransactionStatus.rejected:
      return 'danger';
    case TransactionStatus.accepted:
      return 'success';
    case TransactionStatus.manualReview:
      return 'info';
    case TransactionStatus.warning:
      return 'warning';
    default:
      return status;
  }
};

export const getCodeToDisplay = (code: string) => {
  switch (code) {
    case TransactionTypes.LIST_MATCHING:
      return 'List Matching';
    case TransactionTypes.COUNTRIES:
      return 'Countries';
    case TransactionTypes.TXN:
      return 'Transactional Analysis';
    default:
      return code;
  }
};

export const TransactionTypes = {
  LIST_MATCHING: 'listmatching',
  COUNTRIES: 'countries',
  TXN: 'txn',
};
