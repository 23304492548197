import {
  Typography,
  Badge,
  CopyToClipboard,
  Tooltip,
  SkeletonLoader,
  Skeleton,
} from '@gbm/queen-ui-guidelines';
import { FC } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { formatDateTimeTZUser } from 'utils/dates';
import getDisplayId from 'utils/getDisplayId';
import { getColorForBadge, getStatusToDisplay } from 'utils/transactionsUtils';
import styles from './styles.module.scss';

type HeaderProps = {
  id: string | undefined;
  status: string | undefined;
  nameLabelTitle: string | undefined;
  valueLabelTitle: string | undefined;
  loading: boolean;
  createdAt: string | undefined;
  updatedAt: string | undefined;
};

const Header: FC<HeaderProps> = ({
  id,
  status,
  nameLabelTitle,
  valueLabelTitle,
  loading,
  createdAt,
  updatedAt,
}) => (
  <>
    <header className={styles.header}>
      {loading ? (
        <div className={styles.loaderContainer}>
          <SkeletonLoader>
            <Skeleton height={40} width="25%" />
            <Skeleton height={20} width={100} />
          </SkeletonLoader>
        </div>
      ) : (
        <>
          <div className={styles.txHeaderLeft}>
            <div className={styles.txIdContainer}>
              <Typography
                className={styles.title}
                variant="headerTitle"
                weight="bold"
              >
                Transaction:
              </Typography>
              <Typography
                variant="headerTitle"
                weight="bold"
                className={styles.title}
              >
                <CopyToClipboard
                  messageBeforeCopy="Copiar"
                  messageAfterCopy="¡Copiado!"
                  value={id}
                >
                  {getDisplayId(id || '')}
                </CopyToClipboard>
              </Typography>
            </div>
            <div className={styles.txInfoContainer}>
              <Badge
                className={styles.badge}
                color={getColorForBadge(status)}
                variant="pill"
              >
                {getStatusToDisplay(status)}
              </Badge>
              <Tooltip
                position="bottom"
                message={`Created at: ${formatDateTimeTZUser(
                  createdAt ?? '',
                )} Updated at: ${formatDateTimeTZUser(updatedAt ?? '')}`}
              >
                <FaInfoCircle className={styles.infoIcon} />
              </Tooltip>
            </div>
          </div>
          {/* <div className={styles.namesContainer}>
            <div>
              <div className={styles.containerValues}>
                <div className={styles.containerValue}>
                  <Typography weight="bold">
                    {startCase(nameLabelTitle)}:
                  </Typography>
                  <Typography
                    weight="bold"
                    variant="headerTitle"
                    className={styles.nameLabelValue}
                  >
                    {valueLabelTitle}
                  </Typography>
                </div>
              </div>
            </div>
          </div> */}
        </>
      )}
    </header>
  </>
);

export default Header;
