import { FC } from 'react';
import {
  Margin,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  TableHead,
  TableColumn,
} from '@gbm/queen-ui-guidelines';
import { ManualReviewDetail, UserManualReview } from 'types/Transaction';
import { getStatusToDisplay } from 'utils/transactionsUtils';

export interface ModalDetailDataProps {
  onClose: () => void;
  isOpen: boolean;
  user: UserManualReview | undefined;
  manualReview: ManualReviewDetail | undefined;
}

const ModalDetailVerification: FC<ModalDetailDataProps> = ({
  onClose,
  isOpen,
  user,
  manualReview,
}) => {
  return (
    <Modal
      id="ModalDetailVerification"
      data-testid="modalDetailVerification"
      isOpen={isOpen}
      size="lg"
      scrollable
      centered
    >
      <ModalHeader onClose={onClose}>Detail verification</ModalHeader>
      <ModalBody>
        <Margin side="bottom" xs={24}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography component="strong" variant="subHeader">
                    User
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>{user?.user}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography component="strong" variant="subHeader">
                    User name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>{user?.userName}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography component="strong" variant="subHeader">
                    Status
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    {getStatusToDisplay(manualReview?.status)}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography component="strong" variant="subHeader">
                    Comments
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>{manualReview?.comments}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography component="strong" variant="subHeader">
                    Complementary comments
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>{manualReview?.complementaryComments}</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Margin>
      </ModalBody>
      <ModalFooter>
        <Button
          id="btnCloseModalComplementary"
          data-testid="btnCloseModalComplementary"
          type="button"
          color="light"
          onClick={onClose}
        >
          Cerrar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalDetailVerification;
