import {
  Margin,
  Typography,
  Button,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
  Skeleton,
  DisplayMessage,
} from '@gbm/queen-ui-guidelines';
import DividerName from 'components/DividerName';
import { nanoid } from 'nanoid';
import Feature from 'providers/FeatureProvider/Feature';
import { FC } from 'react';
import { FaEdit } from 'react-icons/fa';
import { ValidatorManualReview } from 'types/Transaction';
import { TransactionsToSave } from 'types/TransactionsState';
import { getCodeToDisplay, TransactionTypes } from 'utils/transactionsUtils';
import ValidationToShowCard from './ValidationToShowCard';

interface ValidationsToSaveProps {
  dataValidationsToShow: TransactionsToSave;
  loading: boolean;
  handleUpdateVerification: (validator: ValidatorManualReview) => void;
  checkValidationsChecked: Function;
  setIsOpenConfirmReviewDialog: Function;
}
const ValidationsToSave: FC<ValidationsToSaveProps> = ({
  dataValidationsToShow,
  loading,
  handleUpdateVerification,
  checkValidationsChecked,
  setIsOpenConfirmReviewDialog,
}) => {
  const renderValidationsToSave = () => {
    return Object.entries(dataValidationsToShow)?.map(
      (validators: [string, ValidatorManualReview[]]) => {
        if (validators[1].length > 0) {
          if (validators[0] === TransactionTypes.TXN) {
            return (
              <Accordion collapsible multiple key={nanoid()}>
                {validators[1].map((value: ValidatorManualReview) => (
                  <AccordionItem key={nanoid()}>
                    <AccordionHeader
                      controlLabel={getCodeToDisplay(validators[0])}
                      className="d-flex justify-content-between"
                    >
                      <Button
                        icon
                        size="xs"
                        color="light"
                        onClick={() => handleUpdateVerification(value)}
                      >
                        <FaEdit />
                      </Button>
                    </AccordionHeader>
                    <AccordionPanel>
                      <div key={value.code} className="w-100">
                        <ValidationToShowCard
                          loading={loading}
                          status={value.status}
                          comments={value.details.comments}
                          complementaryComments={
                            value.details.complementaryComments
                          }
                        />
                      </div>
                    </AccordionPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            );
          } else {
            return (
              <DividerName
                title={getCodeToDisplay(validators[0])}
                key={nanoid()}
              >
                <Accordion collapsible multiple>
                  {validators[1].map((value: ValidatorManualReview) => (
                    <AccordionItem key={nanoid()}>
                      <AccordionHeader
                        controlLabel={value.labelToShow}
                        className="d-flex justify-content-between"
                      >
                        <Button
                          icon
                          size="xs"
                          color="light"
                          onClick={() => handleUpdateVerification(value)}
                        >
                          <FaEdit />
                        </Button>
                      </AccordionHeader>
                      <AccordionPanel>
                        <div key={value.code} className="w-100">
                          <ValidationToShowCard
                            loading={loading}
                            status={value.status}
                            comments={value.details.comments}
                            complementaryComments={
                              value.details.complementaryComments
                            }
                          />
                        </div>
                      </AccordionPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              </DividerName>
            );
          }
        } else {
          return <></>;
        }
      },
    );
  };
  return (
    <>
      {dataValidationsToShow.countries.length > 0 ||
      dataValidationsToShow.listmatching.length > 0 ||
      dataValidationsToShow.txn.length > 0 ? (
        <>
          <Margin side="bottom" xs={24}>
            <div className="d-flex justify-content-between">
              <Typography component="h4" align="left">
                Validations data
              </Typography>
            </div>
          </Margin>
          <Margin side="bottom" xs={24}>
            {loading ? (
              <>
                <Skeleton height={30} width="100%" />
                <Skeleton height={30} width="100%" />
              </>
            ) : (
              <>{renderValidationsToSave()}</>
            )}
          </Margin>
          <Feature name="transactionReview">
            <div className="d-flex justify-content-end">
              {loading ? (
                <>
                  <Skeleton height={30} width="40%" />
                </>
              ) : (
                <Button
                  disabled={loading || !checkValidationsChecked()}
                  onClick={() => setIsOpenConfirmReviewDialog(true)}
                >
                  Finish manual review
                </Button>
              )}
            </div>
          </Feature>
        </>
      ) : (
        <DisplayMessage
          message="Complete the manual review data"
          variant={'info'}
        />
      )}
    </>
  );
};

export default ValidationsToSave;
